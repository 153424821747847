import axios from "axios";
import api from "@/store/api";

export const state = function () {
  return {
    fetched: false,
    data: {},
    ingredient: {}
  }
}

export const mutations = {
  SET_STATE (state, {key, value}) {
    state[key] = value
  },
}

export const actions = {
  async get_ingredients ({ state, commit }) {
    try {
      const { data } = await axios.get(api.ingredients)
      commit('SET_STATE', {key: 'data', value: data})
      commit('SET_STATE', {key: 'fetched', value: true})
    } catch (e) {
      // todo catch err - modal with Notification of error bot being saved
    }
  },
  async add_ingredient ({state, commit}) {
    try {
      await axios.post(api.ingredients, state.ingredient)
      commit('RESET_SETTINGS')
    } catch (e) {

    }
  }
}

export const getters = {
  ingredients: function (state, getters ,rootState) {
    console.log(state.data[rootState.lang])
    return state.data[rootState.lang]
  }
}
