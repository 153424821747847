<template>
  <main class="food-view" ref="menuCategories">
    <ul>
      <li
        v-for="{ category, products, categoryId } in drinks"
        :key="categoryId"
        :id="categoryId"
      >
        <h2>{{ category }}</h2>
        <ul>
          <li v-for="product in products" :key="product.id">
            <ProductDetails v-show="product.enabled"
                            :data="product" weight-unit="cl." />
          </li>
        </ul>
      </li>
    </ul>

    <CategoriesListModal :categories="categories" />
    <CallWaiterModal />
  </main>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  import ProductDetails from '../components/ProductDetails'
  import CategoriesListModal from '../components/CategoriesListModal'
  import CallWaiterModal from '@/components/CallWaiterModal.vue'
  const { getters } = useStore()
  const drinks = computed(() => getters.drinks)
  const categories = computed(() => getters.categories)
  const menuCategories = ref(null)
</script>

<style lang="scss" scoped>
  .food-view {
    padding: 11px 16px 150px;
    li ul {
      padding-left: 15px;
    }
  }
  h2 {
    margin-bottom: 0;
    margin-top: 24px;
  }
</style>
