<template>
  <div class="place-order-confirmation">
    <p class="m-t-0 f-18">
      {{ cartText.orderConfirmationModalText }}
    </p>
    <textarea class="block w100 m-b-24"
              v-model="notes"
              placeholder="ex: fara ceapa la quesadilla, sau fara rosii la salata, etc" />
    <div class="flex-center">
      <button class="success-btn"
              @click="handlePlaceOrder">{{ cartText.send }}</button>
    </div>
  </div>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const cartText = computed(() => getters.cartText)
  const emits = defineEmits(['confirm'])
  const notes = ref('')

  function handlePlaceOrder () {
    emits('confirm', {action: 'yes', notes: notes.value})
  }

</script>

<style lang="scss">
  .place-order-confirmation {
    textarea {
      padding: 6px;
      font-size: 1.8rem;
      height: 160px;
    }
  }
</style>