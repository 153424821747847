export const state = {
  landingPageText: {
    navigation: {
      pages: [
        { id: 'about',
          title: {
            ro: 'Despre',
            en: ``
          },
          line: true
        },
        {
          id: 'fixed-problems',
          title: {
            ro: 'Ce rezolvăm'
          },
          line: true
        },
        {
          id: 'what-can-do',
          title: {
            ro: 'Ce oferim'
          },
          line: true
        },
        { id: 'why',
          title: {
            ro: 'De ce QRSM',
            en: ``
          },
          line: true
        },
        { id: 'video',
          title: {
            ro: 'Prezentare',
            en: ``
          },
          line: true
        },
        { id: 'feedback',
          title: {
            ro: 'Feedback',
            en: ``
          },
          line: true
        },
        { id: 'price',
          title: {
            ro: 'Prețuri',
            en: ``
          },
          line: true
        },
        { id: 'faq',
          title: {
            ro: 'F.A.Q.',
            en: ``
          }
        },
      ]
    },
    mainTitle: {
      ro: `Ce-ar fi dacă ai putea să-ți surprinzi clienții la fiecare vizită?`,
      en: ``
    },
    subTitle: {
      ro: `Inovația digitală și ospitalitatea tradițională, un <b>duo perfect</b> pentru succesul restaurantului tău!`,
      en: ``
    },
    presentationSection: {
      description: {
        ro: `Îți prezentăm <b>QR Smart Menu</b>, sistemul inteligent care transformă experiența din restaurantul tău. <br><br>
          Imaginează-ți un meniu digital personalizat, accesibil printr-un simplu cod QR, care vorbește limba fiecărui client. <br><br>
          Fără costuri de printare, fără așteptări lungi, fără bariere lingvistice. <b>QR Smart Menu</b> e mai mult decât un meniu digital - e partenerul tău în satisfacerea clienților. <br><br>
           Curios să vezi cum <b>QR Smart Menu</b> poate transforma restaurantul tău? `,
        en: ``
      },
      showText: {
        ro: `Hai să-ți arătăm!`,
        en: ``
      },
      buttonText: {
        ro: `Încearcă GRATUIT`,
        en: ``
      },
    },
    fixedProblemsSection: {
      title: {
        ro:`Ce probleme rezolvă QR Smart Menu:`,
        en: ``
      } ,
      problems: [
        {
          problem: {
            ro: `Problema personalului neatent sau leneș`,
            en: ``
          },
        },
        {
          problem: {
            ro: `Problema clientului ce stă cu mâna pe sus`,
            en: ``
          },
        },
        {
          problem: {
            ro: `A traducerii meniului în toate limbile internaționale`,
            en: ``
          },
        },
        {
          problem: {
            ro: `A ascunderii unui produs din meniu în timp real dacă nu mai este disponibil`,
            en: ``
          },
        },
        {
          problem: {
            ro: `Afișărilor de fotografii ce atrag clienții`,
            en: ``
          },
        },
        {
          problem: {
            ro: `Citirii meniului de către client ce se chinuie din cauza fontului și că e în format PDF`,
            en: ``
          },
        },
        {
          problem: {
            ro: `A reflectării brandului tău în stilul și tema vizuală a meniului digital`,
            en: ``
          },
        },
      ]
    },
    whatCanDoSection: {
      title: {
        ro: `Ce știe să facă QR Smart Menu pentru tine:`,
        en: ``
      } ,
      functionalities: [
        {
          functionality: {
            ro: `Să notifice ospătarul direct de către client de la masă ca să aducă nota sau alte detalii`,
            en: ``
          }
        },
        {
          functionality: {
            ro: `Să îți traducă meniul în toate limbile internaționale`,
            en: ``
          }
        },
        {
          functionality: {
            ro: `Să îți ascundă în timp real un produs sau mai multe din meniu direct de pe telefon`,
            en: ``
          }
        },
        {
          functionality: {
            ro: `Să încarce fotografii la produse direct de pe telefon`,
            en: ``
          }
        },
        {
          functionality: {
            ro: `Să customizeze tema vizuală a meniului pentru a reflecta brandul tău (logo, imagini, font-uri, culori)`,
            en: ``
          }
        },
        {
          functionality: {
            ro: `Să prezinte meniul într-un format plăcut la citire (NU PDF)`,
            en: ``
          }
        },
      ],
      buttonText: `Vezi cum arată`
    },
    reasonsToUseSection: {
      title: {
        ro: `De ce ar dori clienții tăi să folosească QR Smart Menu:`,
        en: ``
      },
      reasons: [
        {
          reason: {
            ro: `Pentru că e foarte ușor de citit`,
            en: ``
          }
        },
        {
          reason: {
            ro: `Pentru că le oferă opțiunea să comunice direct cu ospătarul`,
            en: ``
          }
        },
        {
          reason: {
            ro: `Pentru că poate citi în limba lor`,
            en: ``
          }
        },
      ]
    },
    videoSection: {
      title: {
        ro: `Iată QR Smart Menu în acțiune:`,
        en: ``
      }
    },
    howToAddSection: {
      title: {
        ro: `Cum adăugați produsele în QR Smart Menu:`,
        en: ``
      },
      description: {
        ro: `Fie importați un Excel, fie manual prin interfața de management content.`,
        en: ``
      }
    },
    feedbackSection: {
      title: {
        ro: `Ce spun patronii despre QR Smart Menu:`,
        en: ``
      },
      comments: [
        {
          comment: {
            ro: `Da, e ce trebuie.`,
            en: ``
          },
          from: {
            ro: `Mihai S. / Joe Pub`,
            en: ``
          }
        },
        {
          comment: {
            ro: `Nu mai trebuie să stau cu ochii pe ospătari, că nu mai trebuie să umble pe la mese.`,
            en: ``
          },
          from: {
            ro: `Aurel Popa  / Joe Pub`,
            en: ``
          }
        },
      ]
    },
    priceSection: {
      title: {
        ro: `Cât costă QR Smart Menu:`,
        en: ``
      },
      options: [
        {
          option: {
            ro: `SIMPLU`,
            en: ``
          },
          price: {
            ro: `140 lei/lună`,
            en: ``
          },
          description: {
            ro: `varianta simplă, menu + traduceri în limbile internaționale`,
            en: ``
          }
        },
        {
          option: {
            ro: `MEDIU`,
            en: ``
          },
          price: {
            ro: `280 lei/lună`,
            en: ``
          },
          description: {
            ro: `opțiune customizare temă conform brandului dumneavoastră`,
            en: ``
          }
        },
        {
          option: {
            ro: `FULL`,
            en: ``
          },
          price: {
            ro: `480 lei/lună <br>
            + 140 lei/ospătar `,
            en: ``
          },
          description: {
            ro: `(oferim telefon și iWatch pentru notificări)`,
            en: ``
          }
        },
      ],
      chooseOption: {
        ro: `alege`,
        en: ``
      },
      buttonText: {
        ro: `COMANDĂ`,
        en: ``
      },
      discountText: {
        ro: `Primii <b>30</b> de clienți beneficiază de gratuitate pentru primele trei luni. <br>
          Clienți până acum: <b>18</b>`,
        en: ``
      }
    },
    faqSection: {
      title: {
        ro: `Intrebări frecvente:`,
        en: ``
      },
      questions: [
        {
          question: {
            ro: `Cât de greu e să setez meniul?`,
            en: ``
          },
          answer: {
            ro: `E floare la ureche, puteți intra de pe un laptop și în aproximativ o oră aveți tot meniul sus, asta dacă nu aveți un excel deja pe care să îl încărcați.`,
            en: ``
          }
        },
        {
          question: {
            ro: `Pot schimba designul când vreau?`,
            en: ``
          },
          answer: {
            ro: `Da, dacă aveți abonament premium.`,
            en: ``
          }
        },
      ]
    },
  }
}

export const getters = {
  landingPage: (state, getters, { lang }) => {
    const translateText = (obj) => {
      return Object.entries(obj).reduce((result, [key, value]) => {
        if (value && typeof value === 'object' && ('ro' in value || 'en' in value)) {
          result[key] = value[lang] || ''
        } else if (value && typeof value === 'object') {
          result[key] = translateText(value)
        } else {
          result[key] = value
        }
        return result
      }, {})
    }
    return translateText(state.landingPageText)
  }
}
