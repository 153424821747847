<template>
  <footer class="landing-page-footer">
    <ul class="flex flex-wrap flex-center mauto">
      <li v-for="(page, index) in landingPage.navigation.pages"
          :key="index"
          @click="scrollToSection(page.id)">
        <span>{{ page.title }}</span>
        <span v-if="page.line">&nbsp;|&nbsp;</span>
      </li>
    </ul>

    <p>Contact: salut@revoluqrmenu.ro | 0700 123 456</p>

    <div>
      <img src="../assets/icons/fb.png" alt="" />
      <img src="../assets/icons/insta.png" alt="" />
    </div>
  </footer>
</template>

<script setup>
  import { computed, inject } from "vue";
  import { useStore } from "vuex";

  const { getters } = useStore();

  const landingPage = computed(() => getters.landingPage)

  const smoothScroll = inject('smoothScroll')

  function scrollToSection (id) {
    const elm = document.getElementById(id);
    smoothScroll({
      scrollTo: elm,
      offset: -60
    })
  }
</script>

<style lang="scss">
  .landing-page-footer {
    @media only screen and (min-width: 0) {
      width: 100%;
      height: 350px;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #331B59;
      color: white;

      ul {
        max-width: 300px;
        display: flex;
        flex-wrap: wrap;
        li {
          font-size: 1.9rem;
          font-weight: 500;
          margin-top: 10px;
          cursor: pointer;
        }
      }

      p {
        margin: 40px 20px;
        color: white;
      }

      div {
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
</style>