import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSmoothScroll from 'vue3-smooth-scroll'
import BaseInput from './components/BaseInput'
import BaseFileUpload from './components/BaseFileUpload'
import AddBtn from './components/dashboard/AddBtn'
import SaveBtn from './components/dashboard/SaveBtn'
import BaseModal from './components/BaseModal'
import ConfirmationModal from './components/ConfirmationModal'
import BaseSearchInput from './components/dashboard/BaseSearchInput'
import BaseMenuModal from './components/BaseMenuModal'
import './styles/helper-classes.scss'
import './styles/typography.scss'
import './styles/base.scss'

const app = createApp(App)

app
  .component('BaseInput', BaseInput)
  .component('BaseFileUpload', BaseFileUpload)
  .component('AddBtn', AddBtn)
  .component('SaveBtn', SaveBtn)
  .component('BaseModal', BaseModal)
  .component('ConfirmationModal', ConfirmationModal)
  .component('BaseSearchInput', BaseSearchInput)
  .component('BaseMenuModal', BaseMenuModal)

app.use(store).use(router).use(VueSmoothScroll).mount('#app')
