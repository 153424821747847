<template>
  <div class="feedback-view">
    <img class="feedback-image flex-center" src="../assets/feedback-image.gif" alt="" />
    <p class="header-text flex-center fw-700">
      {{ feedback.header }}
    </p>
    <form @submit.prevent="sendFeedback">
      <div class="reactions flex flex-between">
        <button
          @click="chooseReaction(reaction)"
          class="circle flex align-center justify-center"
          :class="{ 'on-click': feedbackForm.feeling === reaction }"
          type="button"
          v-for="(reaction, index) in reactions"
          :key="index"
        >
          <img
            :src="require(`../assets/feedback-reactions/${reaction}.png`)"
            :alt="reaction"
          />
        </button>
      </div>

      <div class="flex columns">
        <label class="f-18" for="message">{{ feedback.messageLabel }}</label>
        <textarea
          class="radius-4 f-18"
          id="message"
          :placeholder="feedback.messagePlaceholder"
          v-model.trim="feedbackForm.message"
        ></textarea>
        <span v-show="!formIsValid" class="f-16 color-red">
          {{ feedback.errorMessage }}
        </span>
      </div>

      <div class="name-input-container flex columns">
        <label class="f-18" for="name">{{ feedback.nameLabel }}</label>
        <input
          class="radius-4 f-18"
          type="text"
          id="message"
          placeholder=""
          v-model.trim="feedbackForm.name"
        />
      </div>

      <button class="send-form-btn f-20 fw-700 flex-center" type="submit">
        {{ feedback.buttonText }}
      </button>
    </form>
    <transition>
      <p v-show="messageIsSent" class="message-sent flex-center text-center fw-700">
        {{ feedback.successfullySendMsg }}
      </p>
    </transition>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { computed, ref, watch, reactive } from 'vue'
  const { state, commit, dispatch, getters } = useStore()
  const defaultReaction = ref(state.feedback.defaultReaction)
  const reactions = ref(state.feedback.reactions)
  const feedback = computed(() => getters.feedback)
  const feedbackForm = reactive({
    feeling: defaultReaction,
    message: '',
    name: ''
  })
  const formIsValid = ref(true)
  const messageIsSent = ref(false)

  function chooseReaction(reaction) {
    feedbackForm.feeling = reaction
  }

  watch(
    () => feedbackForm.message,
    (curr, old) => {
      if (curr.length > 0) {
        formIsValid.value = true
      }
    }
  )

  function sendFeedback() {
    if (!feedbackForm.message) {
      formIsValid.value = false
    } else {
      formIsValid.value = true
      dispatch('send_feedback', feedbackForm)
      messageIsSent.value = true
      feedbackForm.message = ''
      feedbackForm.name = ''
      feedbackForm.feeling = defaultReaction
    }
  }
</script>

<style lang="scss" scoped>
  .feedback-view {
    padding: 0 20px 20px;
    background-color: white;
    height: 100%;
    overflow-y: scroll;
    .feedback-image {
      height: auto;
      width: 180px;
      margin: 0 auto;
    }
    .header-text {
      margin: 16px 0 40px;
      font-family: 'Dancing Script', cursive;
      font-size: 24px;
    }

    form {
      margin: 11px 0 20px;
      .reactions {
        width: 160px;
        margin: 0 auto 22px;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 42px;
          width: 42px;
          border: none;
          background-color: white;
          -webkit-tap-highlight-color: transparent;
          overflow: hidden;
        }
        .on-click {
          border-radius: 50%;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
        }
        img {
          height: 40px;
          width: 40px;
          flex-grow: 1;
        }
      }
      textarea {
        margin: 5px 0 3px;
        height: 117px;
        resize: none;
      }
      textarea,
      input {
        border: 1px solid rgb(104, 103, 103);
        padding: 10px;
        &:focus {
          outline: none;
        }
      }
      input {
        margin-top: 5px;
        height: 30px;
      }
      .name-input-container {
        margin-top: 15px;
      }
      .send-form-btn {
        background-color: #128b35;
        border-radius: 8px;
        border-style: none;
        -webkit-tap-highlight-color: transparent;
        color: white;
        height: 40px;
        width: 114px;
        margin: 30px auto 0;
      }
      .send-form-btn:active {
        background-color: #10792e;
      }
      button:focus,
      button:active {
        outline: none;
        box-shadow: none;
      }
    }
    .message-sent {
      color: #10792e;
      font-size: 28px;
      margin: 0 auto;
      font-family: 'Dancing Script', cursive;
    }
  }
</style>
