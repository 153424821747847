<template>
  <div class="confirmation-modal">
    <p>{{ msg }}</p>
    <div class="flex flex-between">
      <button cy="confirm-cancel-btn"
              @click="handleAction('cancel')">NU</button>
      <button cy="confirm-accept-btn"
              class="no-border"
              @click="handleAction('accept')">DA</button>
    </div>
  </div>
</template>

<script setup>
  const emits = defineEmits(['accept', 'cancel'])
  const props = defineProps({
    msg: String
  })

  function handleAction (action) {
    emits(action)
  }
</script>

<style lang="scss">
  .confirmation-modal {
    p {
      margin-top: 0;
      font-size: 2rem;
    }
    div button:last-child {
      padding: 0 16px;
      border-radius: 4px;
      background-color: green;
      color: white;
    }
  }
</style>
