<template>
  <div class="order-waiting-notification">
    <p class="m-t-0 f-18">
      Comanda ta a fost trimisa, si este in asteptare pana cand
      toti cei de la masa s-au decis ce doresc sa comande!
      <br>
      Intreaba-i te rog daca sunt gata, si daca da, te rugam sa apesi butonul de confirmare.
      Daca nu sunt gata, imediat ce s-au hotarat si au plasat si ei comanda, apasa butonul de mai jos.
    </p>

    <button class="success-btn" @click="handleConfirm">Da, trimite comanda!</button>
  </div>
</template>

<script setup>
  const emits = defineEmits(['confirm'])

  function handleConfirm () {
    emits('confirm', {})
  }
</script>

<style lang="scss">
  .order-waiting-notification {

  }
</style>