<template>
  <div class="base-modal fixed flex-center left0 top0 z99 wh100"
       cy="base-modal">
    <div>
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
  .base-modal {
    background-color: rgba(0, 0, 0, 0.5);
    > div {
      max-width: 400px;
      padding: 24px;
      border-radius: 4px;
      background-color: white;
    }
  }
</style>
