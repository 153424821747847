<template>
  <div class="inform-client-modal">
    <p>{{ msg }}</p>
    <div class="flex-center">
      <button class="success-btn"
              @click="handleClose">OK</button>
    </div>
  </div>
</template>

<script setup>
  import {useStore} from "vuex";

  const props = defineProps({
    msg: String
  })
  const { commit } = useStore()
  function handleClose () {
    commit('SET_MODAL_NAME', '')
  }
</script>

<style lang="scss">
  .inform-client-modal {

  }
</style>