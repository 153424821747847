<template>
  <main class="cart-view flex columns flex-center align-center justify-center">
    <BaseMenuModal>
      <PlaceOrderConfirmation v-if="modalName === 'placeOrder'"
                              @confirm="handlePlaceOrder" />
      <OrderWaitingNotification v-if="modalName === 'orderWaiting'"
                              @confirm="handleConfirmOrder" />
    </BaseMenuModal>

    <h1>{{ cartText.title }}</h1>

    <p v-show="!cart.length"
       class="text-center">
      {{ cartText.noProduct }}
      <img
          class="product-separator-icon"
          src="../assets/fancy-divider.png"
          alt=""
      />
    </p>

    <ul class="reset" cy="cart-list-products">
      <li class="reset" v-for="product in cart"
          :key="product.id">
        <ShoppingCartProduct :product="product" />
      </li>
    </ul>

    <div v-if="cartTotalProducts > 0">
      <p class="total-cart">
        <span>Total: {{ cartTotalPrice }} lei</span>
      </p>
    </div>

    <div class="actions-btns flex columns justify-center">
      <button v-show="isOrders"
              class="flex align-center m-b-24"
              @click="handlePlaceOrderPreview">
        <span>{{ cartText.orderBtn }}</span>
        <img src="../assets/icons/food-tray.png" alt="chef-hat" />
      </button>

      <div v-show="isNoteCheck">
        <h2 class="m-t-0 text-center">Cere nota:</h2>
        <div class="flex-center">
          <button class="m-r-16" @click="handleCheck('cash')">Cash</button>
          <button @click="handleCheck('card')">Card</button>
        </div>
        <p v-if="checkRequest" class="text-center">Nota a fost ceruta.</p>
      </div>
    </div>
  </main>
</template>

<script setup>
  import { io } from "socket.io-client";
  import ShoppingCartProduct from '../components/ShoppingCartProduct.vue'
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex'
  import PlaceOrderConfirmation from "@/components/PlaceOrderConfirmation.vue";
  import BaseMenuModal from "@/components/BaseMenuModal.vue";
  import OrderWaitingNotification from "@/components/OrderWaitingNotification.vue";

  const socket = io('wss://qrmenu.testenv.ro');
  const { state, getters, dispatch, commit } = useStore()
  const cart = computed(() => getters.cart)
  const cartText = computed(() => getters.cartText)
  const lang = computed(() => state.lang)
  const checkRequest = computed(() => state.menu.checkRequest)
  const cartTotalProducts = computed(() => getters.cartTotalProducts)
  const cartTotalPrice = computed(() => getters.cartTotalPrice)
  const isOrders = computed(() => cart.value.some(o => !o.ordered))
  const isNoteCheck = computed(() => cart.value.some(o => o.ordered))
  const modalName = computed(() => state.modalName)

  socket.on('connect', () => {
    console.log(`socket connected`)
  });

  socket.on('message', function (data) {
    console.log(data)
    commit('SET_MODAL_NAME', '')
  });

  function handlePlaceOrderPreview () {
    commit('SET_MODAL_NAME', 'placeOrder')
  }
  function handlePlaceOrder ({notes}) {
    dispatch('place_order', {notes})
  }

  function handleConfirmOrder (data) {
    socket.emit('confirm_order', 'order confirmed table 5');
  }

  function handleCheck (type) {
    dispatch('get_check', type)
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .cart-view {
    padding: 16px 16px 136px;
    overflow-y: scroll;
    > p {
      margin-top: 0;
      font-size: 2rem;
      img {
        display: block;
        margin: 24px auto 0;
        height: 20px;
      }
    }
    .empty-cart-container {
      width: 65%;
      margin-top: 30px;
      .empty-cart-text {
        margin-bottom: 10px;
      }
      img {
        height: 100px;
      }
    }
    ul {
      width: 100%;
    }
    .title {
      width: 70%;
      margin-bottom: 27px;
      color: #128b35;
    }
    .total-cart {
      font-size: 2rem;
      color: $dark;
    }
    .actions-btns {
      button {
        padding: 4px 16px;
        background-color: $bright-green;
        border-radius: 4px;
        color: white;
        font-size: 2rem;
        border: none;
        img {
          height: 32px;
          margin-left: 8px;
        }
      }
    }
  }
</style>
