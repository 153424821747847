export const state = {
  categoriesModalText: {
    ro: 'Alegeti categoria dorita',
    en: 'Select desired category'
  },
  isCategoriesModalVisible: false,
  isCallWaiterModalVisible: false,
  isCategoriesModalButtonVisible: true,
  isCallWaiterModalButtonVisible: true,
  callWaiterModalText: {
    callWaiter: { ro: 'Cheama ospatarul', en: 'Call the waiter' },
    orderButtonText: { ro: 'Comanda', en: 'Order' },
    askTheBill: { ro: 'Cere nota de plata', en: 'Ask for the bill' },
    cashPayment: { ro: 'Numerar', en: 'Cash' },
    cardPayment: { ro: 'Card', en: 'Card' }
  }
}

export const mutations = {
  TOGGLE_CALL_WAITER_MODAL(state) {
    state.isCallWaiterModalVisible = !state.isCallWaiterModalVisible
    state.isCallWaiterModalButtonVisible = !state.isCallWaiterModalButtonVisible
    state.isCategoriesModalButtonVisible = !state.isCallWaiterModalVisible
  },
  SHOW_CALL_WAITER_MODAL(state, value) {
    state.isCallWaiterModalVisible = value
    state.isCallWaiterModalButtonVisible = !value
    state.isCategoriesModalButtonVisible = !value
  },
  TOGGLE_CATEGORIES_MODAL(state) {
    state.isCategoriesModalVisible = !state.isCategoriesModalVisible
    state.isCategoriesModalButtonVisible = !state.isCategoriesModalButtonVisible
    state.isCallWaiterModalButtonVisible = !state.isCategoriesModalVisible
  },
  SHOW_CATEGORIES_MODAL(state, value) {
    state.isCategoriesModalVisible = value
    state.isCategoriesModalButtonVisible = !value
    state.isCallWaiterModalButtonVisible = !value
  }
}

export const getters = {
  callWaiterModalText: (state, getters, { lang }) => {
    return Object.entries(state.callWaiterModalText).reduce((result, [key, value]) => {
      result[key] = value[lang]
      return result
    }, {})
  }
}
