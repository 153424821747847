<template>
  <div v-if="isModal"
       class="base-modal fixed flex-center left0 top0 z99 wh100"
       cy="base-modal">
    <div>
      <slot />
    </div>
  </div>
</template>

<script setup>
  import { useStore } from "vuex";
  import { computed } from "vue";

  const { state } = useStore()
  const isModal = computed(() => state.isModal)
</script>

<style lang="scss">
  .base-modal {
    background-color: rgba(0, 0, 0, 0.5);
    > div {
      max-width: 400px;
      padding: 24px;
      margin: 0 16px;
      border-radius: 4px;
      background-color: white;
    }
  }
</style>
