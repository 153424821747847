<template>
  <footer class="main-footer fixed left0 bottom0 z99 w100"
          @click="closeModals">
    <div class="flex flex-center">
      <nav class="flex flex-between"
           cy="footer-menu">
        <router-link
            v-for="({icon, route, name, className}, index) in menu"
            :key="index"
            :class="route"
            :to="`/${restaurantId}/menu/${route}`"
        >
          <figure class="circle">
            <img :class="`w100 ${className}`" :src="icon" alt="" />
            <span class="block m-t-8 f-14 text-center">{{ name }}</span>
          </figure>
        </router-link>
      </nav>
    </div>

    <i class="absolute hide">
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17" cy="17" r="17" fill="#FBFADD"/>
        <path d="M24.4516 15.6978L15.1812 6.53872C15.0083 6.36793 14.8031 6.23244 14.5772 6.14001C14.3514 6.04758 14.1093 6 13.8648 6C13.3711 6 12.8976 6.19378 12.5484 6.53872C12.3756 6.70952 12.2384 6.91229 12.1449 7.13544C12.0513 7.3586 12.0032 7.59778 12.0032 7.83932C12.0032 8.32714 12.1993 8.79497 12.5484 9.13991L20.5209 16.9984L12.5484 24.857C12.3747 25.0273 12.2367 25.2299 12.1426 25.4531C12.0485 25.6763 12 25.9157 12 26.1576C12 26.3994 12.0485 26.6388 12.1426 26.862C12.2367 27.0853 12.3747 27.2879 12.5484 27.4581C12.7208 27.6298 12.9259 27.7661 13.1518 27.8591C13.3777 27.9521 13.6201 28 13.8648 28C14.1096 28 14.3519 27.9521 14.5779 27.8591C14.8038 27.7661 15.0089 27.6298 15.1812 27.4581L24.4516 18.299C24.6253 18.1287 24.7633 17.9261 24.8574 17.7029C24.9515 17.4797 25 17.2403 25 16.9984C25 16.7566 24.9515 16.5172 24.8574 16.294C24.7633 16.0707 24.6253 15.8681 24.4516 15.6978Z" fill="#1C362B"/>
      </svg>
    </i>
  </footer>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  import glasses from '../assets/icons/glasses-pair.png'
  import menuBook from '../assets/icons/menu-book.png'
  import bell from '../assets/icons/bell-icon-1.png'
  import tobacco from '../assets/icons/cigarette.png'
  import agronomy from '../assets/icons/agronomy.png'

  const { commit } = useStore()
  const router = useRouter()
  const restaurantId = router.currentRoute.value.params.restaurant_id
  const menu = [
    {icon: glasses, route: 'drinks', name: 'Drinks'},
    {icon: menuBook, route: 'food', name: 'Food'},
    {icon: bell, className: 'bell-icon', route: 'notify-waiter', name: 'Waiter'},
    {icon: tobacco, route: 'tigari'},
    {icon: agronomy, route: 'allergens'},
  ]
  const lang = localStorage.getItem('lang')
  function closeModals() {
    commit('SHOW_CATEGORIES_MODAL', false)
    commit('SHOW_CALL_WAITER_MODAL', false)
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  @keyframes bell-ring {
    0%, 90%, 100% { transform: translateY(0) rotate(0); }
    92% { transform: translateY(0) rotate(15deg); }
    94% { transform: translateY(0) rotate(-15deg); }
    96% { transform: translateY(0) rotate(10deg); }
    98% { transform: translateY(0) rotate(-10deg); }
  }

  .main-footer {
    background-color: $dark;
    height: 66px;
    nav {
      transform: translateX(10px);
    }
    a {
      figure {
        width: 60px;
        height: 60px;
        padding: 8px;
        transform: translateY(-16px);
        border: 3px solid $dark;
        background-color: white;
        /* ... existing styles ... */
        img {
          &.bell-icon {
            animation: bell-ring 4s infinite;
            //transform-origin: 50% 0;
          }
        }
      }
      span {
        color: white;
      }
      &.tigari,
      &.allergens {
        figure {
          width: 44px;
          height: 44px;
          transform: translateY(-6px);
        }
      }
      &:not(:last-child) {
        figure {
          margin-right: 8px;
        }
      }
    }
    .router-link-active {
      figure {
        background-color: $alternative;
      }
    }
  }
</style>
