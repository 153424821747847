<template>
  <main class="food-view">
    <ul cy="tigari-list" class="flex-column">
      <li v-for="{ brand, price, id } in cigarettes" :key="id">
        <div class="flex columns justify-center cigarette">
          <div class="flex row">
            <img class="bullet-point" src="../assets/bullet-point.png" alt="" />
            <span>{{ brand }}</span>
          </div>
          <div class="price-container flex flex-between row align-center">
            <h3 class="price-tag text-center">{{ price }} lei</h3>
          </div>
        </div>
      </li>
    </ul>
    <CallWaiterModal />
  </main>
</template>

<script setup>
  import AddToCartBtn from '../components/AddToCartBtn'
  import CallWaiterModal from '@/components/CallWaiterModal.vue'
  import { useStore } from 'vuex'
  import { computed } from 'vue'
  const { state, commit } = useStore()
  const cigarettes = computed(() => state.menu.tigari)

  function addToCart(productId) {
    commit('ADD_CIGARETTE_TO_CART', productId)
  }
</script>

<style lang="scss" scoped>
  .food-view {
    overflow-y: scroll;
    padding: 11px 16px 150px;
    width: 100%;
    ul {
      .cigarette {
        padding-top: 25px;
        font-size: 20px;
      }
      .bullet-point {
        height: 15px;
        width: 15px;
        margin: 7px 5px 0 0;
      }
      .price-container {
        margin: 10px 0;
        padding-right: 10px;
      }
      .price-tag {
        white-space: nowrap;
        margin: auto 0 auto 20px;
        float: left;
        font-weight: 700;
      }
    }
  }
</style>
