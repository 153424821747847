<template>
  <button class="save-btn no-border flex-center"
          :disabled="disabled"
          :cy="cy || 'save-btn'"
          @click="handleClick">
    {{ text || 'Save' }}
  </button>
</template>

<script setup>
  const props = defineProps({
    disabled: {
      type: Boolean
    },
    cy: String,
    text: String,
  })
  const emit = defineEmits(['save'])

  function handleClick () {
    emit('save')
  }
</script>

<style lang="scss">
  @import "../../styles/vars.scss";

  .save-btn {
    @media only screen and (min-width: 0) {
      background-color: $bright-green;
      color: white;
      font-size: 1.6rem;
      border-radius: 4px;
      padding: 8px 16px;
    }
  }
</style>
