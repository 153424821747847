<template>
  <main class="welcome-view">
    <LogoImg />

    <p>
      {{ infos.description.ro }}
      <br />
      <img src="../assets/plate.png" alt="" />
      <br />
      {{ infos.description.en }}
    </p>

    <div class="language-selection" cy="language-selector">
      <button @click="handleLanguage('ro')" cy="lang-ro-btn">
        <img src="../assets/flag-ro.svg" alt="" />
        <br />
        RO
      </button>
      <button @click="handleLanguage('en')">
        <img src="../assets/flag-en.svg" alt="" />
        <br />
        EN
      </button>
    </div>
  </main>
</template>

<script setup>
  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import LogoImg from '../components/LogoImg'

  const router = useRouter()
  const { state, commit, dispatch } = useStore()
  const infos = computed(() => state.menu.infos)
  const id = computed(() => state.restaurant_id)
  const isChangingLanguage = computed(() => state.isChangingLanguage)
  dispatch('get_menu', 'infos')
  const lang = localStorage.getItem('lang')
  const table = router.currentRoute.value.query.table
  lang && !isChangingLanguage.value && router.push(`/${id.value}/menu/drinks${table ? '?table=' + table : ''}`)

  function handleLanguage(lang) {
    commit('SET_LANGUAGE', lang)
    router.push(`/${id.value}/menu/drinks${table ? '?table=' + table : ''}`)
  }
</script>

<style lang="scss">
  .welcome-view {
    padding: 48px;
    text-align: center;
    > p {
      font-family: Capsuula, sans-serif;
      font-size: 3rem;
      img {
        width: 80%;
      }
    }
    button {
      background-color: transparent;
      font-family: Capsuula, sans-serif !important;
      border: none;
      font-size: 3rem;
      &:first-child {
        margin-right: 24px;
      }
    }
  }
</style>
