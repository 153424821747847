import axios from "axios"
import api from "../api"
import router from '../../router'

export const state = {
  user: undefined
}

export const actions = {
  async login ({commit, dispatch}, user) {
    try {
      const { data } = await axios.post(api.login, user)
      commit('SET_USER', data)
      await router.push('/dashboard')
    } catch (err) {
      console.log(err)
    }
  },
  async logout ({commit}, user) {
    try {
      await axios.delete(api.logout)
      commit('SET_USER', {})
      await router.push('/login')
    } catch (err) {
      console.log(err)
    }
  },
  async get_user ({commit, dispatch}, next) {
    try {
      const {data: user} = await axios.get(api.checkLogin)
      if (user.name) {
        commit('SET_USER', user)
        commit('SET_RESTAURANT_ID', user.name, { root: true })
        next()
      }
    } catch (e) {
      next('/login')
    }
  },
}

export const mutations = {
  SET_USER (state, user) {
    state.user = user
  }
}
