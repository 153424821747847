import axios from "axios"
import api from '../api'

export const state = function () {
  return {
    isModal: false,
    selectedId: undefined
  }
}

export const mutations = {
  SET_ALLERGENS_STATE (state, {key, value}) {
    state[key] = value
  }
}

export const actions = {
  async delete_allergens ({commit}, id) {
  	try {
      const url = `${api.menu}?type=allergens&productId=${id}`
  		await axios.delete(url)
      commit('DELETE_ALLERGEN', id)
      commit('SET_ALLERGENS_STATE', {key: 'isModal', value: false})
      commit('SET_SIDE_COMPONENT', '')
  	}
  	catch (err) {}
  }
}
