import axios from "axios";
import api from "@/store/api";

export const state = {
  isWaitingWaiter: false,
  waitingWaiting: '00:00',
}

export const mutations = {
  SET_WAITING (state, payload) {
    state.isWaitingWaiter = payload
  },
  SET_WAITING_TIME (state, time) {
    state.waitingTime = time
  }
}

export const actions = {
  async call_waiter ({state, commit}, {message, notes}) {
    try {
      const table = localStorage.getItem('table') || '1'
      let title = `Masa ${table}`
      let body = notes
      if (notes) {
        title += ` 💬`
      }
      if (message.includes('CASH')) {
        title = `Masa ${table} 💰`
        body = `plata CASH \n${notes}`
      }
      if (message.includes('CARD')) {
        title = `Masa ${table} 💳`
        body = `plata CARD \n${notes}`
      }
      await axios.post(`${api.notifications}/${localStorage.getItem('restaurant_id')}/${table}`, {
        title,
        body,
      })
      commit('SET_WAITING', true)
    } catch (err) {
      console.log(err)
    }
  }
}