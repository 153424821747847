<template>
  <figure>
    <img :src="`/logo/qrmenu-${id}/infos/logo.png`" alt="" />
  </figure>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const { state, commit, dispatch } = useStore();
const id = computed(() => state.restaurant_id);
</script>

<style lang="scss" scoped>
figure {
  img {
    height: 32px;
  }
}
</style>
