import axios from "axios";
import api from "@/store/api";

export const state = function () {
  return {
    fetched: false,
    data: {
      name: '',
      no_of_tables: '',
      description: {},
    },
    updated: {}
  }
}

export const mutations = {
  SET_STATE (state, {key, value}) {
    state[key] = value
  },
  SET_SETTINGS (state, {name, value}) {
    state.updated = {
      [name]: value
    }
  },
  RESET_SETTINGS (state, {name, value}) {
    state.updated = {}
  }
}

export const actions = {
  async get_settings ({ state, commit }) {
    try {
      const { data } = await axios.get(api.settings)
      commit('SET_STATE', {key: 'data', value: data})
      commit('SET_STATE', {key: 'fetched', value: true})
    } catch (e) {
      // todo catch err - modal with Notification of error bot being saved
    }
  },
  async create_zone ({state, commit}) {
    try {
      const data = {
        name: 'terasa',
        tables: [1, 2]
      }
      await axios.post(`${api.settings}/areas`, data)
      commit('RESET_SETTINGS')
    } catch (e) {

    }
  },
  async update_zone ({state, commit}) {
    try {
      const data = {
        name: 'terasa',
        tables: [1, 2]
      }
      const id = 'area id'
      await axios.put(`${api.settings}/areas/${id}`, data)
      commit('RESET_SETTINGS')
    } catch (e) {

    }
  },
  async delete_zone ({state, commit}) {
    try {
      const data = {
        name: 'terasa',
        tables: [1, 2]
      }
      const id = 'area id'
      await axios.delete(`${api.settings}/areas/${id}`, data)
      commit('RESET_SETTINGS')
    } catch (e) {

    }
  },
  async update_settings ({state, commit}) {
    try {
      await axios.post(api.settings, state.updated)
      commit('RESET_SETTINGS')
    } catch (e) {

    }
  }
}

