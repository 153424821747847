import { createStore } from 'vuex'
import * as auth from './modules/auth'
import * as menu from './modules/menu'
import * as feedback from './modules/feedback'
import * as modals from './modules/modals'
import * as allergens from './modules/allergens'
import * as settings from './modules/settings'
import * as ingredients from './modules/ingredients'
import * as notifications from './modules/notifications'
import * as landingPage from './modules/landingPage'

export const state = {
  restaurant_id: '',
  sideComponentName: '',
  isChangingLanguage: false,
  isModal: false,
  modalName: '',
  confirmationComponent: '',
  isMenuConfirmation: false,
  confirmationDetails: {
    actionType: '',
    name: '',
    data: undefined
  },
  lang: 'ro',
  cartText: {
    title: {
      ro: 'Comanda dumneavoastra',
      en: 'Your order'
    },
    noProduct: {
      ro: 'Nu aveti nici un produs adaugat in lista.',
      en: `You don't have any products in the list.`,
    },
    orderValueText: {
      ro: 'Valoarea comenzii*',
      en: 'Order value*'
    },
    note: {
      ro: 'Aceasta pagina are scop informativ. Adaugarea produselor in cos nu presupune plasarea comenzii.',
      en: 'This page is for information purposes. Adding products to the cart does not imply placing the order.'
    },
    emptyCart: {
      ro: 'Cosul tau este gol.',
      en: 'Your cart is empty.'
    },
    emptyCartAdditionalText: {
      ro: 'Pentru a adauga produse in cos te rugam sa accesezi meniul.',
      en: 'To add products to the cart, please go to the menu.'
    },
    orderBtn: {
      ro: 'Plasați comanda',
      en: 'Place your order'
    },
    toastConfirmOrder: {
      ro: 'Produsul a fost adaugat in comanda.',
      en: 'Your product has been added to order.'
    },
    deleteConfirmation: {
      ro: 'Sunteti siguri ca doriti sa stergeti acest produs?',
      en: 'Are you sure you want to delete this item?',
    },
    yes: {
      ro: 'Da',
      en: 'Yes',
    },
    no: {
      ro: 'Nu',
      en: 'No',
    },
    send: {
      ro: 'Trimite',
      en: 'Send',
    },
    orderConfirmationModalText: {
      ro: 'Inainte de a plasa comanda, va rog sa specificati mentiunile pentru bucatar.',
      en: 'Before placing the order please specify the notes for the chef',
    },
    checkNotificationMsg: {
      ro: 'Un ospatar va sosi indata cu nota de plata!',
      en: '',
    }
  },
  toastNotif: false,
  isDeleteModal: false,
  isPlaceOrderModal: false,
  checkRequest: true,
  textContent: {
    ro: {
      ingredients: {
        title: 'Ingrediente'
      },
      settings: {
        title: 'Setari'
      }
    },
    en: {
      ingredients: {
        title: 'Ingredients'
      },
      settings: {
        title: 'Settings'
      }
    }
  }
}

export const mutations = {
  SET_TOAST_NOTIF (state) {
    state.toastNotif = true
    setTimeout(() => {
      state.toastNotif = false
    }, 2000)
  },
  SET_MODAL (state, {modal, value}) {
    state[modal] = value
  },
  SET_MODAL_NAME (state, value) {
    state.isModal = !!value
    // state.isMenuConfirmation = !!value
    state.modalName = value
  },
  SET_CONFIRMATION (state, value) {
    state.isMenuConfirmation = value
  },
  SET_CONFIRMATION_DETAILS (state, value) {
    state.confirmationDetails = value
  },
  SET_VALUE (state, { key, value }) {
    state[key] = value
  },
  SET_RESTAURANT_ID (state, id) {
    state.restaurant_id = id
    localStorage.setItem('restaurant_id', id)
  },
  SET_LANGUAGE (state, lang) {
    state.lang = lang
    state.isChangingLanguage = false
    localStorage.setItem('lang', lang)
  },
  SET_SIDE_COMPONENT (state, name) {
    state.sideComponentName = name
  },
  SET_RESTAURANT_TABLE(state, table) {
    state.table = table
    localStorage.setItem('table', table)
  },
}

export const getters = {
  text: function (state) {
    return state.textContent[state.lang]
  },
  cartText: function (state) {
    return Object.keys(state.cartText).reduce((acc, key) => {
      acc[key] = state.cartText[key][state.lang]
      return acc
    }, {})
  }
}

const store = createStore({
  state,
  getters,
  mutations,
  actions: {},
  modules: {
    auth,
    menu,
    feedback,
    modals,
    allergens,
    settings,
    ingredients,
    notifications,
    landingPage,
  }
})

export default store
