<template>
  <div
    v-show="isModalVisible"
    cy="show-modal-btn"
    class="pulse-button flex-center z99 fixed circle"
    @click="handleClick"
  >
    <slot></slot>
  </div>
</template>

<script setup>
  const emit = defineEmits(['showModal'])
  const props = defineProps({
    cy: String,
    isModalVisible: { default: false, type: Boolean }
  })

  function handleClick() {
    emit('showModal')
  }
</script>

<style lang="scss" scoped>
  .pulse-button {
    margin: 10px;
    height: 60px;
    width: 60px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
    border: 2px solid rgb(0, 0, 0, 0.4);
    transform: scale(1);
    animation: pulse 2s infinite, showButton 0.3s ease-in-out;

    img {
      height: 42px;
    }
  }

  @keyframes showButton {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(153, 152, 152, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(13, 194, 43, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(13, 194, 43, 0);
    }
  }
</style>
