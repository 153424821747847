<template>
  <div class="menu-confirmation">
    <p>
      {{ cartText[text] }}
    </p>
    <div class="flex-center">
      <button @click="handleConfirm('no')" class="m-r-16">{{ cartText.no }}</button>
      <button @click="handleConfirm('yes')">{{ cartText.yes }}</button>
    </div>
  </div>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const cartText = computed(() => getters.cartText)
  const props = defineProps({
    text: String
  })
  const emits = defineEmits(['confirm'])

  function handleConfirm (action) {
    emits('confirm', {action})
  }
</script>

<style lang="scss">
  .menu-confirmation {

  }
</style>