<template>
  <div class="product-details flex columns relative">
    <span v-if="data.color"
          :class="`color-dot absolute ${data.color}-color`" />

    <div class="product-title flex row">
      <img class="bullet-point"
           src="../assets/bullet-point.png" alt="" />
      <div class="w100">
        <h3 class="product-name">{{ data.name }}</h3>
      </div>
      <img
          v-if="data.vegan"
          class="logo-vegans"
          src="../assets/logo-vegani-fbk.png"
          alt=""
      />
    </div>

    <p class="product-description"
       v-if="data.description">
      <span class="f-18">{{ data.description }}</span>
      <span v-if="data.weight" class="weight-tag block">
          ({{ data.weight }} {{ weightUnit || 'gr.' }})
        </span>
    </p>

    <div class="price-container flex row align-center">
      <span class="price-tag block m-left-auto">{{ data.price }} lei</span>
<!--      <AddToCartBtn @addToCart="addToCart(data.categoryId, data.id)" />-->
    </div>

    <figure v-if="data.img">
      <img class="w100" :src="`/images/${restaurant_id}/${data.img}`" alt="" />
    </figure>
  </div>

</template>

<script setup>
  import AddToCartBtn from '../components/AddToCartBtn'
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  const { state, commit, dispatch } = useStore()
  const props = defineProps({
    data: Object,
    weightUnit: String
  })
  const restaurant_id = computed(() => state.restaurant_id)

  function addToCart(categoryId, productId) {
    commit('ADD_TO_CART', { categoryId, productId })
    commit('SET_TOAST_NOTIF')
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .product-details {
    .color-dot {
      margin-top: 7px;
    }
    h3 {
      margin-bottom: 0;
      margin-top: 8px;
      color: rgba(0, 0, 0, 0.9);
      font-weight: 100;
    }

    span.absolute {
      width: 16px;
      height: 16px;
      left: -24px;
      top: 6px;
      border-radius: 50%;
    }
    .color-white {
      border: 1px solid #330a04;
    }
    .product-name {
      padding-right: 6px;
      color: $black-green;
    }
    .product-title-container {
      margin-bottom: -21px;
    }
    .bullet-point {
      height: 15px;
      width: 15px;
      margin: 14px 5px 0 0;
      opacity: .5;
    }
    .logo-vegans {
      width: 42px;
      height: 42px;
      margin-top: 4px;
    }
    .price-tag {
      margin-left: auto;
      margin-top: 4px;
      font-size: 2rem;
      color: $dark-gray;
    }
    .weight-tag {
      font-size: 1.5rem;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    .product-description {
      margin-left: 20px;
    }
    figure {
      margin-top: 8px;
      img {
        border-radius: 4px;
      }
    }
  }
</style>
