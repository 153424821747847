<template>
  <header class="landing-page-menu">
    <img src="../assets/icons/menu-logo.png" alt="" />
    <img v-if="isMobile" class="menu-icon" @click="toggleMobileMenu"
         :src="isMobileMenuOpen ? './images/menu-icon.png' : './images/menu-icon.png'" :class="{ 'close-menu-icon': isMobileMenuOpen, 'menu-icon': !isMobileMenuOpen }"
         alt="" />
    <div v-if="isMobile && isMobileMenuOpen" class="mobile-menu">
      <ul>
        <li v-for="page in landingPage.navigation.pages" :key="page.id" @click="scrollToSection(page.id)">
          {{ page.title }}
        </li>
      </ul>
    </div>

    <ul v-if="!isMobile" class="desktop-menu">
      <li v-for="page in landingPage.navigation.pages" :key="page.id">
        <a :href="'#' + page.id" @click.prevent="scrollToSection(page.id)">{{ page.title }}</a>
        <span>~</span>
      </li>
    </ul>
  </header>
</template>

<script setup>
  import { computed, inject, onMounted, onUnmounted, ref } from "vue";
  import { useStore } from "vuex";

  const { getters } = useStore();

  const landingPage = computed(() => getters.landingPage)

  const isMobile = ref(true);
  const isMobileMenuOpen = ref(false);

  const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
  };

  const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 1023;
  };

  onMounted(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
  });

  const smoothScroll = inject('smoothScroll')

  function scrollToSection (id) {
    const elm = document.getElementById(id);
    smoothScroll({
      scrollTo: elm,
      offset: -60
    })
    isMobileMenuOpen.value = false;
  }
</script>

<style lang="scss">
  .landing-page-menu {
    @media only screen and (min-width: 0) {
      width: 100%;
      height: 65px;
      position: fixed;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: white;
      border-bottom: 1px solid black;
      padding: 0 20px;
      z-index: 1;

      .menu-icon, .close-menu-icon {
        cursor: pointer;
        z-index: 2;
      }

      img:first-child {
        width: 46px;
        height: 46px;
        z-index: 2;
      }

      .menu-icon {
        width: 31px;
        height: 31px;
      }

      .mobile-menu {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: white;
      }

      ul {
        li {
          font-size: 1.9rem;
          font-weight: 500;
          color: #270066;
          margin-top: 15px;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      .desktop-menu{
        display: flex;

        a, span {
          margin-left: 10px;
          font-size: 1.9rem;
          font-weight: 600;
          text-decoration: none;
          color: #310080;
        }

        li:last-child {
          //padding-right: 20px;
          span {
            display: none;
          }
        }
      }
    }
  }
</style>