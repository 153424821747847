<template>
  <button class="no-border bg-white flex-center"
          :cy="cy || 'add-btn'"
          @click="handleClick">
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3 25.5H18.7V18.7H25.5V15.3H18.7V8.5H15.3V15.3H8.5V18.7H15.3V25.5ZM17 34C14.6483 34 12.4383 33.5535 10.37 32.6604C8.30167 31.7685 6.5025 30.5575 4.9725 29.0275C3.4425 27.4975 2.23153 25.6983 1.3396 23.63C0.446534 21.5617 0 19.3517 0 17C0 14.6483 0.446534 12.4383 1.3396 10.37C2.23153 8.30167 3.4425 6.5025 4.9725 4.9725C6.5025 3.4425 8.30167 2.23097 10.37 1.3379C12.4383 0.445967 14.6483 0 17 0C19.3517 0 21.5617 0.445967 23.63 1.3379C25.6983 2.23097 27.4975 3.4425 29.0275 4.9725C30.5575 6.5025 31.7685 8.30167 32.6604 10.37C33.5535 12.4383 34 14.6483 34 17C34 19.3517 33.5535 21.5617 32.6604 23.63C31.7685 25.6983 30.5575 27.4975 29.0275 29.0275C27.4975 30.5575 25.6983 31.7685 23.63 32.6604C21.5617 33.5535 19.3517 34 17 34ZM17 30.6C20.7967 30.6 24.0125 29.2825 26.6475 26.6475C29.2825 24.0125 30.6 20.7967 30.6 17C30.6 13.2033 29.2825 9.9875 26.6475 7.3525C24.0125 4.7175 20.7967 3.4 17 3.4C13.2033 3.4 9.9875 4.7175 7.3525 7.3525C4.7175 9.9875 3.4 13.2033 3.4 17C3.4 20.7967 4.7175 24.0125 7.3525 26.6475C9.9875 29.2825 13.2033 30.6 17 30.6Z" fill="black"/>
    </svg>
  </button>
</template>

<script setup>
  const emit = defineEmits(['add'])
  const props = defineProps({
    cy: String
  })

  function handleClick () {
    emit('add')
  }
</script>

<style scoped>
  button {
    font-size: 3.6rem;
    background-color: transparent;
  }
</style>
