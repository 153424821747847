<template>
  <main class="food-view" ref="menuCategories">
    <ul cy="categories-list">
      <li
        cy="choose-category"
        v-for="{ category, categoryId, products } in food"
        :key="categoryId"
        :id="categoryId"
      >
        <h2 v-if="products.some(o => o.enabled)"><b>{{ category }}</b></h2>
        <ul cy="products-list">
          <li  v-show="product.enabled"
               v-for="(product, index) in products"
              :key="product.id">
            <div>
              <ProductDetails :data="product" />
              <img
                v-if="index < products.length - 1"
                class="product-separator-icon"
                src="../assets/fancy-divider.png"
                alt=""
              />
            </div>
            <br />
          </li>
        </ul>
        <div v-if="products.some(o => o.enabled)"
             class="category-separator text-center">
          <img src="../assets/category-separator-icon.png" alt="menu" />
        </div>
      </li>
    </ul>
    <CategoriesListModal :categories="categories" />
    <CallWaiterModal />
  </main>
</template>

<script setup>
  import ProductDetails from '../components/ProductDetails'
  import CategoriesListModal from '../components/CategoriesListModal'
  import CallWaiterModal from '@/components/CallWaiterModal.vue'

  import { useStore } from 'vuex'
  import { computed, ref } from 'vue'
  const { getters } = useStore()
  const food = computed(() => getters.food)
  const categories = computed(() => getters.categories)
  const menuCategories = ref(null)
</script>

<style lang="scss" scoped>
  @import "../styles/vars";

  .food-view {
    overflow-y: scroll;
    padding: 11px 16px 150px;

    ul {
      h2 {
        margin-bottom: 0;
        margin-top: 12px;
        color: $dark-green;
        font-weight: normal;
        text-align: center;
        font-size: 2.4rem;
      }
      li {
        div.category-separator {
          img {
            height: 50px;
            opacity: 0.6;
          }
        }
        ul {
          padding-left: 0;
          li div {
            > img {
              width: 30%;
              margin: 16px auto 0;
              display: block;
              opacity: 0.5;
            }
          }
        }
      }
    }
  }
</style>
