<template>
  <main class="allergens-view">
    <ul>
      <li v-for="({ name, value, alergeni, id }, index) in data" :key="id">
        <h3>{{ name }}</h3>
        <p>{{ value }}</p>
        <i>{{ alergeni }}</i>
        <img
          class="allergens-divider block"
          v-if="index < data.length - 1"
          src="../assets/fancy-divider.png"
          alt=""
        />
      </li>
    </ul>
    <p id="note">*valorile nutritionale sunt calculate pt 100g produs</p>

    <CallWaiterModal />
  </main>
</template>

<script setup>
  import CallWaiterModal from '@/components/CallWaiterModal.vue'
  import { useStore } from 'vuex'
  import { computed } from 'vue'

  const { state, commit, getters, dispatch } = useStore()
  const data = computed(() => getters.allergens)
</script>

<style lang="scss">
  .allergens-view {
    padding: 11px 16px 120px;
    h3 {
      margin-bottom: 8px;
    }
    #note {
      width: 100%;
      left: 0;
      bottom: 32px;
      padding: 12px 5px 32px;
      position: fixed;
      background-color: white;
      box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.4);
      -webkit-box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.4);
    }
    li p {
      margin-top: 0;
      margin-bottom: 6px;
    }

    .allergens-divider {
      width: 30%;
      margin: 20px auto 30px;
    }
  }
</style>
