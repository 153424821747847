<template>
  <div class="cart-product w100 flex columns flex-between align-center">
    <div class="flex row flex-between justify-center w100">
      <p class="f-18 m-r-8 reset product-title flex align-start">{{ product.name }} </p>

      <button
          v-if="!product.ordered"
        cy="remove-product"
        @click="removeFromCart(product.id)"
        class="remove-product"
      >
        <i>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0C6.2 0 0 6.2 0 14C0 21.8 6.2 28 14 28C21.8 28 28 21.8 28 14C28 6.2 21.8 0 14 0ZM19.4 21L14 15.6L8.6 21L7 19.4L12.4 14L7 8.6L8.6 7L14 12.4L19.4 7L21 8.6L15.6 14L21 19.4L19.4 21Z" fill="#1C362B"/>
          </svg>
        </i>
      </button>
      <i v-else>
        <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M26.3566 0.42225C26.5404 0.57315 26.6913 0.757267 26.8008 0.96408C26.9103 1.17089 26.9761 1.39635 26.9946 1.62755C27.0131 1.85876 26.9838 2.09119 26.9085 2.31156C26.8332 2.53193 26.7132 2.73591 26.5555 2.91185L11.8165 19.3837C11.6513 19.5684 11.4479 19.7183 11.2193 19.8242C10.9907 19.93 10.7417 19.9894 10.488 19.9987C10.2342 20.008 9.98124 19.9669 9.74486 19.8781C9.50848 19.7893 9.29382 19.6546 9.11435 19.4825L0.516582 11.2466C0.181139 10.9136 -0.00434658 10.4677 7.73551e-05 10.005C0.00450129 9.54225 0.19848 9.09969 0.540234 8.77263C0.881989 8.44557 1.34417 8.26017 1.82724 8.25637C2.31031 8.25256 2.77562 8.43066 3.12293 8.7523L10.3156 15.6399L23.76 0.615206C24.0781 0.260319 24.5303 0.0409025 25.0171 0.00516326C25.504 -0.0305759 25.9857 0.117933 26.3566 0.42225Z" fill="#1eb386"/>
        </svg>
      </i>
    </div>

    <div class="flex row flex-between align-center price-container">
      <span cy="count-price"
            class="price-per-quantity f-18 fw-700"
        >{{ product.count }} x {{ product.price }} lei
      </span>

      <div v-if="!product.ordered"
           class="count-actions flex align-center fw-700 relative">
        <button :class="{'opa-2': product.count === 1}"
                @click="decrement(product.id)"
                :disabled="product.count === 1">
          <svg width="34"
               height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_24_217" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="34">
              <path d="M29.5 2H4.5C3.11929 2 2 3.11929 2 4.5V29.5C2 30.8807 3.11929 32 4.5 32H29.5C30.8807 32 32 30.8807 32 29.5V4.5C32 3.11929 30.8807 2 29.5 2Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round"/>
              <path d="M10.3331 17H23.6665" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </mask>
            <g mask="url(#mask0_24_217)">
              <path d="M-3.00024 -3.00002H36.9998V37H-3.00024V-3.00002Z" fill="#12372A"/>
            </g>
          </svg>
        </button>

        <span>{{ product.count }}</span>

        <button @click="increment(product.id)">
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_24_223" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="34">
              <path d="M29.5 2H4.5C3.11929 2 2 3.11929 2 4.5V29.5C2 30.8807 3.11929 32 4.5 32H29.5C30.8807 32 32 30.8807 32 29.5V4.5C32 3.11929 30.8807 2 29.5 2Z" fill="white" stroke="white" stroke-width="4" stroke-linejoin="round"/>
              <path d="M17.0002 10.3333V23.6667M10.3335 17H23.6668" stroke="black" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            </mask>
            <g mask="url(#mask0_24_223)">
              <path d="M-2.99988 -3.00002H37.0001V37H-2.99988V-3.00002Z" fill="#12372A"/>
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { useStore } from 'vuex'
  const { commit } = useStore()
  const props = defineProps({
    product: {
      type: Object,
      required: true
    }
  })

  function removeFromCart (productId) {
    commit('SET_MODAL', {modal: 'isDeleteModal', value: true})
    commit('SET_CONFIRMATION', true)
    commit('SET_CONFIRMATION_DETAILS', {actionType: 'mutation', name: 'REMOVE_FROM_CART', data: productId})
  }

  function increment (productId) {
    commit('INCREMENT_PRODUCT_COUNT', productId)
  }

  function decrement (productId) {
    commit('DECREMENT_PRODUCT_COUNT', productId)
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .cart-product {
    min-height: 100px;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 26px;
    padding: 10px;

    button {
      padding: 0;
      border: none;
      background-color: transparent;
    }

    .count-actions {
      span {
        display: inline-block;
        margin: 0 8px 6px;
        font-size: 2rem;
        color: $dark;
      }
      svg {
        g path {
          fill: $regular;
        }
      }
    }
    .remove-product {
      align-self: baseline;
      path {
        fill: $gray;
      }
    }

    .price-container {
      width: 100%;
      margin-top: 8px;
    }
    .product-title {
      width: 100%;
    }
    .price-per-quantity {
      margin: 0 5px 0 0;
      color: $dark;
    }
    button:active {
      outline: none;
      box-shadow: none;
    }
  }
</style>
