<template>
  <main class="login-view">
    <h1>Login</h1>
    <form @submit.prevent="handleLogin">
      <label for="email">
        <input id="email"
               v-model="username"
               type="text" placeholder="email">
      </label>
      <label for="password">
        <input id="password"
               v-model="password"
               type="text" placeholder="password">
      </label>
      <button class="w100 m-t-16 no-border">Login</button>
    </form>
  </main>
</template>

<script setup>
  import {useStore} from "vuex"
  import {ref} from "vue"

  const {dispatch} = useStore()
  const username = ref('')
  const password = ref('')

  function handleLogin () {
    dispatch('login', {username: username.value, password: password.value})
  }
</script>

<style lang="scss">
  .login-view {
    padding-top: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    h1 {
      font-size: 3rem;
    }
    label {
      display: block;
    }
    input {
      width: 300px;
      padding: 6px 0 6px 12px;
      margin-bottom: 8px;
      border: 1px solid lightgray;
      font-size: 1.8rem;
    }
    button {
      background-color: #1eb386;
      color: white;
      font-size: 2rem;
      border-radius: 4px;
      padding: 6px;
    }
  }
</style>
