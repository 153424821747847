<template>
  <label class="base-input"
         :for="name"
         :cy="cy || name">
    <span class="m-b-6 inline-block">{{ label || name }}:</span>
    <br>
    <textarea v-if="textarea"
              :id="name"
              :value="value"
              @keydown="handleRules"
              @input="handleInput"
              :placeholder="placeholder || label || name" />
    <input v-else
           type="text"
           :id="name"
           :value="value"
           @keydown="handleRules"
           @input="handleInput"
           :placeholder="placeholder || label || name">
    <span class="color-red"
          v-if="required">camp obligatoriu</span>
  </label>
</template>

<script setup>
  const props = defineProps({
    name: {
      type: String
    },
    textarea: {
      type: Boolean
    },
    label: {
      type: String
    },
    placeholder: {
      type: String,
    },
    value: {
      type: [String, Number]
    },
    required: {
      type: Boolean
    },
    rules: {
      type: String,
    },
    cy: {
      type: String
    }
  })

  const emit = defineEmits(['input'])

  function handleInput (event) {
    if (props.required && !event.target.value) {
      return
    }
    emit('input', {name: props.name, value: event.target.value});
  }
  function handleRules (event) {
    switch (props.rules) {
      case 'number':
        if (event.key === 'Backspace' || event.key === 'Delete' ||
            event.key === 'Escape' || event.key === 'Enter' || event.key === 'Tab' ||
            (event.key >= '0' && event.key <= '9') ||
            (event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
        } else {
          event.preventDefault();
        }
        break;
    }
  }
</script>

<style lang="scss">
  .base-input {
    display: inline-block;
    input, textarea {
      padding-left: 8px;
      font-size: 1.8rem;
      line-height: 1.4;
    }
    textarea {
      padding: 8px;
    }
  }
</style>
