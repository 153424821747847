import axios from 'axios'
import api from '../api'

export const state = {
  months: [
    'Ianuarie',
    'Februarie',
    'Martie',
    'Aprilie',
    'Mai',
    'Iunie',
    'Iulie',
    'August',
    'Septembrie',
    'Octombrie',
    'Noiembrie',
    'Decembrie'
  ],
  reviews: [],
  defaultReaction: 'happy',
  reactions: ['sad', 'happy', 'fantastic'],
  feedbackText: {
    header: {
      ro: 'Ne-am bucura sa stim daca ti-a placut la noi. De aceea, te rugam sa ne lasi un mesaj despre servirea si produsele noastre.',
      en: 'We would be happy to know if you had a good experience. Please leave a message about our service and products.'
    },
    messageLabel: {
      ro: 'Mesaj',
      en: 'Message'
    },
    messagePlaceholder: {
      ro: 'Mancarea a fost delicioasa, dar ...',
      en: 'The food was delicious, but ...'
    },
    nameLabel: {
      ro: 'Nume (optional)',
      en: 'Name (optional)'
    },
    errorMessage: {
      ro: 'Va rugam sa lasati un mesaj.',
      en: 'Please leave a message.'
    },
    successfullySendMsg: {
      ro: 'Multumim pentru feedback!',
      en: 'Thank you for your feedback!'
    },
    buttonText: {
      ro: 'Trimite',
      en: 'Send'
    }
  }
}

export const mutations = {
  SET_FEEDBACK(state, reviews) {
    state.reviews = reviews
    console.log(state.reviews)
  }
}

export const actions = {
  async send_feedback({ rootState, commit }, data) {
    try {
      const id = rootState.restaurant_id
      await axios.post(`${api.reviews}?id=${id}`, data)
    } catch (err) {
      console.log(err)
    }
  },
  async get_feedback({ commit, rootState }) {
    try {
      const id = rootState.restaurant_id
      const { data } = await axios(`${api.reviews}?id=${id}`)
      console.log(data)
      commit('SET_FEEDBACK', data)
    } catch (err) {
      console.log(err)
    }
  }
}

export const getters = {
  feedback: (state, getters, { lang }) => {
    return Object.entries(state.feedbackText).reduce((result, [key, value]) => {
      result[key] = value[lang]
      return result
    }, {})
  },

  reviewsData: (state) => {
    const numberOfReactions = {}
    const months = new Set()
    const years = new Set()
    state.reactions.forEach((reaction) => (numberOfReactions[reaction] = 0))
    const formattedReviews = state.reviews.map((review) => {
      const date = new Date(review.createdAt)
      const month = state.months[date.getMonth()]
      months.add(month)
      const year = date.getFullYear()
      years.add(year)
      numberOfReactions[review.feeling]++
      const formattedReview = {
        ...review,
        name: review.name ? review.name : 'Anonim',
        createdAt: formatDate(date),
        month,
        year
      }
      return formattedReview
    })

    return {
      reviews: formattedReviews,
      numberOfReactions,
      years: [...years],
      months: [...months]
    }
  }
}

function formatDate(date) {
  let month = state.months[date.getMonth()],
    day = date.getDate(),
    year = date.getFullYear()

  return [day, month, year].join(' ')
}
