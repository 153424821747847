<template>
  <div class="menu-layout">
    <div v-if="isMenuConfirmation"
         class="menu-confirmation-modal flex-center fixed wh100 left0 top0 z999">
      <MenuConfirmation v-if="isDeleteModal" text="deleteConfirmation"
                        @confirm="handleConfirmation" />
      <PlaceOrderConfirmation v-if="isPlaceOrderModal"
                              @confirm="handlePlaceOrder" />

      <InformClientModal v-if="modalName === 'inform'"
                         :msg="cartText.checkNotificationMsg" />
    </div>

    <MainHeader />

    <div class="menu-content">
      <router-view />
    </div>

    <MainFooter />
  </div>
</template>

<script setup>
  import MainFooter from '../components/MainFooter'
  import MainHeader from '../components/MainHeader'
  import { useRouter, useRoute } from 'vue-router'
  import { useStore } from 'vuex'
  import {computed} from "vue";
  import MenuConfirmation from "@/components/MenuConfirmation.vue";
  import PlaceOrderConfirmation from "@/components/PlaceOrderConfirmation.vue";
  import InformClientModal from "@/components/InformClientModal.vue";

  const { state, commit, dispatch, getters } = useStore()
  dispatch('get_menu')
  const router = useRouter()
  const lang = localStorage.getItem('lang')
  const table = router.currentRoute.value.query.table
  !lang && router.push(`/${router.currentRoute.value.params.restaurant_id}${table ? '?table=' + table : ''}`)
  const modalName = computed(() => state.modalName)
  const isMenuConfirmation = computed(() => state.isMenuConfirmation)
  const confirmationDetails = computed(() => state.confirmationDetails)
  const isDeleteModal = computed(() => state.isDeleteModal)
  const isPlaceOrderModal = computed(() => state.isPlaceOrderModal)
  const cartText = computed(() => getters.cartText)
  function handleConfirmation ({action}) {
    switch (action) {
      case 'yes':
        const {actionType, name, data} = confirmationDetails.value
        if (actionType === 'mutation') {
          commit(name, data);
        }
        commit('SET_CONFIRMATION', false);
        break;
      case 'no':
        commit('SET_CONFIRMATION', false);
        break;
    }
    commit('SET_MODAL', {modal: 'isDeleteModal', value: false})
  }

  function handlePlaceOrder ({notes}) {
    dispatch('place_order', {notes})
  }
</script>

<style lang="scss">
  @import "../styles/vars";

  .menu-layout {
    .menu-confirmation-modal {
      background-color: rgba($dark, 0.6);
      > div {
        padding: 16px;
        margin: 0 16px;
        background-color: white;
        border-radius: 4px;
        p {
          margin-top: 0;
          font-size: 1.8rem;
        }
        div button {
          border: none;
          padding: 4px 12px;
          font-size: 1.8rem;
          border-radius: 4px;
          color: $dark;
          &:last-child {
            background-color: $dark;
            color: $alternative;
          }
        }
      }
    }
    .menu-content {
      position: relative;
      top: 53px;
      margin-bottom: auto;
      height: calc(100% - 107px);
    }
  }
</style>
