<template>
	<div class="base-search-input relative">
    <input type="text"
           @input="handleInput"
           v-model="searchString"
           placeholder="cauta" />

    <i v-show="searchString"
       @click="handleClearSearch"
       class="absolute">
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="black"/>
      </svg>
    </i>
    <i v-show="!searchString"
       class="absolute">
      <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 10.5C8.26142 10.5 10.5 8.26142 10.5 5.5C10.5 2.73858 8.26142 0.5 5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5C0.5 8.26142 2.73858 10.5 5.5 10.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M14.571 14.5L9 9" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </i>
  </div>
</template>

<script setup>
  import {ref} from "vue"

  const props = defineProps({
    value: String,
  })
  const emits = defineEmits(['search', 'clear'])
  const searchString = ref(props.value)

  function handleInput () {
    emits('search', searchString.value)
  }

  function handleClearSearch () {
    searchString.value = ''
    emits('clear')
  }
</script>

<style lang="scss">
	.base-search-input {
    input {
      width: 100%;
      padding: 6px 8px 6px 12px;
      border: 1px solid lightgray;
      border-radius: 4px;
      font-size: 1.6rem;
    }
    i {
      top: 12px;
      right: 8px;
    }
	}
</style>
