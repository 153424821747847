<template>
  <transition name="fade">
    <div
      class="backdrop z-9 wh100"
      v-show="isCategoriesModalVisible"
      @click="toggleCategoriesModal"
    ></div>
  </transition>

  <transition name="slide">
    <div
      id="container"
      ref="container"
      class="categories-modal z-99 flex columns"
      v-show="isCategoriesModalVisible"
    >
      <div class="list-title text-center" ref="modalHeader">
        <span class="f-18">{{ modalText }}</span>
        <img class="title-img" src="../assets/separator-products.png" alt="" />
      </div>
      <ul cy="categories-list-modal" ref="list" class="flex columns scrollY">
        <li
          class="f-16"
          v-for="category in categories"
          :key="category.id"
          @click.stop="scrollToCategory(category.id)"
        >
          {{ category.name }}
        </li>
      </ul>
      <div class="flex-center modal-footer" ref="modalFooter">
        <img
          v-show="showToScroll"
          class="icon-arrow align-self-center"
          src="../assets/arrow.png"
          alt=""
        />
        <img
          @click="toggleCategoriesModal"
          class="close-button"
          src="../assets/close-button.png"
          alt=""
        />
      </div>
    </div>
  </transition>

  <ModalButton
    :isModalVisible="isCategoriesModalButtonVisible"
    class="pulse-button-component"
    @showModal="toggleCategoriesModal"
  >
    <img
      class="categories-button-img"
      src="../assets/list-icon-3.png"
      alt="categories list"
    />
  </ModalButton>
</template>

<script setup>
  import ModalButton from '@/components/ModalButton.vue'
  import { useStore } from 'vuex'
  import { computed, ref, nextTick } from 'vue'
  const { state, commit } = useStore()
  const showToScroll = ref(false)
  const container = ref(null)
  const list = ref(null)
  const modalHeader = ref(null)
  const modalFooter = ref(null)
  const props = defineProps({
    categories: Array
  })
  const isCategoriesModalVisible = computed(() => state.modals.isCategoriesModalVisible)
  const isCategoriesModalButtonVisible = computed(
    () => state.modals.isCategoriesModalButtonVisible
  )
  const lang = computed(() => state.lang)
  const modalText = computed(() => state.modals.categoriesModalText[lang.value])

  function toggleCategoriesModal() {
    commit('TOGGLE_CATEGORIES_MODAL')
    if (isCategoriesModalVisible.value) {
      nextTick(() => {
        showToScroll.value =
          container.value.scrollHeight <
          list.value.scrollHeight +
            modalHeader.value.scrollHeight +
            modalFooter.value.scrollHeight +
            30 // 30px is the top and bottom margin for list
      })
    }
  }

  function findPosition(obj) {
    let curtop = 0
    if (obj.offsetParent) {
      do {
        curtop += obj.offsetTop
      } while ((obj = obj.offsetParent))
      return curtop
    }
  }

  function scrollToCategory(id) {
    commit('SHOW_CATEGORIES_MODAL', false)
    window.scroll(0, findPosition(document.getElementById(id)) - 80, {
      behavior: 'smooth'
    })
  }
</script>

<style lang="scss" scoped>
  @import "../styles/vars";

  .pulse-button-component {
    bottom: 60px;
    right: 8px;
  }

  .backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0);
    top: 0;
    left: 0;
    animation: fade 1s ease-in-out forwards;
  }
  .categories-modal {
    width: 70%;
    height: calc(100% - 107px);
    background-color: $white;
    top: 53px;
    left: 0;
    border-left: 1px solid black;
    position: fixed;
    transform: translateX(0);
    border-bottom-right-radius: 4px;
    overscroll-behavior: contain;
    overflow: auto;

    .list-title {
      padding: 30px 0 0;
    }

    ul {
      margin: 10px 0 20px;
      padding-left: 15px;
      width: 96%;

      li {
        margin-bottom: 8px;
        font-size: 2rem;
      }
    }
    .title-img {
      margin-top: 5px;
      width: 60%;
      align-self: center;
    }
    .icon-arrow {
      height: 20px;
      width: 30px;
      margin-right: 15px;
      margin-left: calc(50% - 15px);
      margin-bottom: 25px;
    }
    .close-button {
      margin-left: auto;
      margin-right: 15px;
      margin-bottom: 15px;
      height: 20px;
      width: 20px;
    }
    .modal-footer {
      bottom: 0;
      margin-top: auto;
    }
  }

  .categories-button-img {
    height: 42px;
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s ease-in-out;
  }
  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(-100%);
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.4;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.1;
    }
  }

  .fade-enter-from {
    animation: fade 0.5s ease-in forwards;
  }
  .fade-leave-to {
    animation: fade-out 0.4s ease-out forwards;
  }
</style>
