<template>
  <main class="landing-page-view">
    <LandingPageHeader />

    <section id="about" class="presentation-section">
      <h1>{{ landingPage.mainTitle }}</h1>
      <h2 v-html="landingPage.subTitle" />
      <img src="../assets/icons/handshake.png" alt="">
      <p v-html="landingPage.presentationSection.description" />
      <span>{{ landingPage.presentationSection.showText }}</span>
      <button>{{ landingPage.presentationSection.buttonText }}</button>
    </section>

    <section id="fixed-problems" class="fixed-problems-section">
      <h2 class="blue-title">{{ landingPage.fixedProblemsSection.title }}</h2>
      <ul>
        <li v-for="(problem, index) in landingPage.fixedProblemsSection.problems" :key="index">
          <img src="../assets/icons/problemIcon.png" alt="">
          <span>{{ problem.problem }}</span>
        </li>
      </ul>
    </section>

    <section id="what-can-do" class="what-can-do-section">
      <h2 class="blue-title">{{ landingPage.whatCanDoSection.title }}</h2>
      <ul>
        <li v-for="(functionality, index) in landingPage.whatCanDoSection.functionalities" :key="index">
          <img src="../assets/icons/functionalityIcon.png" alt="">
          <span>{{ functionality.functionality }}</span>
        </li>
      </ul>
      <button>{{ landingPage.whatCanDoSection.buttonText }}</button>
    </section>

    <section id="why" class="reasons-section">
      <h2 class="blue-title">{{ landingPage.reasonsToUseSection.title }}</h2>
      <ul>
        <li v-for="(reason, index) in landingPage.reasonsToUseSection.reasons" :key="index">
          <img src="../assets/icons/functionalityIcon.png" alt="">
          <span>{{ reason.reason }}</span>
        </li>
      </ul>
    </section>

    <section id="video" class="video-section">
      <h2 class="blue-title">{{ landingPage.videoSection.title }}</h2>
    </section>
    <section class="how-to-add-section">
      <h2 class="blue-title">{{ landingPage.howToAddSection.title }}</h2>
      <p>{{ landingPage.howToAddSection.description }}</p>
    </section>

    <section id="feedback" class="feedback-section">
      <h2 class="blue-title">{{ landingPage.feedbackSection.title }}</h2>
      <ul>
        <li v-for="(comment, index) in landingPage.feedbackSection.comments" :key="index">
          <p>"{{ comment.comment }}"</p>
          <span>{{ comment.from }}</span>
        </li>
      </ul>
    </section>

    <section id="price" class="price-section">
      <h2 class="blue-title">{{ landingPage.priceSection.title }}</h2>
      <ul>
        <li v-for="(option, index) in landingPage.priceSection.options" :key="index">
          <span class="option">{{ option.option }}</span>
          <span v-html="option.price" class="price" />
          <p>{{ option.description }}</p>
          <div class="flex">
            <input type="radio" name="price" :id="option.option" value="option.option" />
            <label :for="option.option">{{ landingPage.priceSection.chooseOption }}</label>
          </div>
        </li>
      </ul>
      <button>{{ landingPage.priceSection.buttonText }}</button>
    </section>

    <section id="faq" class="faq-section">
      <h2 class="blue-title">{{ landingPage.faqSection.title }}</h2>
      <ul>
        <li v-for="(question, index) in landingPage.faqSection.questions" :key="index">
          <p class="question">{{ question.question }}</p>
          <p>{{ question.answer }}</p>
        </li>
      </ul>
    </section>

  <LandingPageFooter />
  </main>
</template>

<script setup>
  import { useStore } from "vuex";
  import { computed } from "vue";
  import LandingPageHeader from "@/components/LandingPageHeader";
  import LandingPageFooter from "@/components/LandingPageFooter";

  const { getters } = useStore();

  const landingPage = computed(() => getters.landingPage)
</script>

<style lang="scss">
  .landing-page-view {
    * {
      font-family: Montserrat, sans-serif !important;
    }

    @media only screen and (min-width: 0px) {
      background-color: white;

      .presentation-section, .fixed-problems-section, .what-can-do-section, .reasons-section,
      .video-section, .how-to-add-section, .feedback-section, .price-section, .faq-section {
        margin: 0 20px;
        padding: 20px 0;
        p, li, span {
          color: #270066;
        }
      }

      .presentation-section {
        padding-top: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1, h2, b {
          text-align: center;
          color: #310080;
        }

        h1 {
          font-size: 2.3rem;
          font-weight: 600;
        }

        h2 {
          margin: 0;
          font-size: 1.9rem;
          font-weight: 500;
        }

        img {
          width: 86px;
          height: 58px;
          margin: 20px 0;
        }

        p {
          font-size: 1.8rem;
          font-weight: 400;
        }

        span {
          margin: 10px 0;
          font-size: 1.9rem;
          font-weight: 400;
        }

        button {
          width: 216px;
          height: 38px;
          font-size: 1.8rem;
          font-weight: 600;
          border-radius: 4px;
          border: none;
          background-color: #8E66FE;
          color: white;
        }
      }

      .blue-title {
        font-size: 1.9rem;
        font-weight: 600;
        color: #027BAF;
      }

      .fixed-problems-section, .what-can-do-section, .reasons-section {
        display: flex;
        flex-direction: column;

        h2 {
          display: flex;
          align-self: flex-start;
        }

        li {
          display: flex;
          align-items: flex-start;
          margin: 0 0 15px 3px;
          font-size: 1.8rem;

          img {
            width: 17px;
            height: 17px;
            margin-top: 3px;
          }

          span {
            margin-left: 10px;
          }
        }

        button {
          width: 216px;
          height: 38px;
          font-size: 1.8rem;
          font-weight: 600;
          border-radius: 4px;
          border: none;
          background-color: #8E66FE;
          color: white;
          align-self: center;
        }
      }

      .fixed-problems-section {
        li:last-child {
          margin-bottom: 0;
        }
      }

      .feedback-section {
        h2 {
          margin-bottom: 0;
        }

        li:first-child {
          p {
            margin-top: 20px;
          }
        }
        p {
          margin: 50px 0 0 0;
          font-size: 1.7rem;
          font-weight: 500;
        }

        span {
          margin-top: 7px;
          float: right;
        }
      }

      .price-section {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2 {
          display: flex;
          align-self: flex-start;
        }

        li {
          margin-bottom: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .option {
            font-size: 2.2rem;
            font-weight: 600;
          }

          .price {
            font-size: 2rem;
            font-weight: 500;
            text-align: center;
          }

          p {
            width: 200px;
            margin: 8px 0 0 0;
            text-align: center;
            font-weight: 500;
          }
        }

        input[type="radio"] {margin-right: 5px;
          transform: scale(1.5);
          z-index: 0;
        }

        li:first-child {
          span, input, label, p {
            color: #05646A;
          }

          input[type="radio"] {
            accent-color: #05646A;
          }

        }
        li:nth-child(2) {
          span, input, label, p {
            color: #126F96;

            input[type="radio"] {
              accent-color: #126F96;
            }
          }
        }
        li:last-child {
          span, input, label, p {
            color: #964212;
          }

          input[type="radio"] {
            accent-color: #964212;
          }

          input {
            border-color: #964212;
          }
        }

        button {
          width: 216px;
          height: 38px;
          font-size: 1.8rem;
          font-weight: 600;
          border-radius: 4px;
          border: none;
          background-color: #45B500;
          color: white;
        }
      }

      .faq-section {
        padding-top: 0;
        li {
          margin-bottom: 20px;

          p {
            margin: 0 0 5px 0;
            color: #270066;
          }

          .question {
            margin-top: 0;
            font-size: 1.7rem;
            font-weight: 600;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      .presentation-section, .fixed-problems-section, .what-can-do-section, .reasons-section,
      .video-section, .how-to-add-section, .feedback-section, .price-section, .faq-section {
        margin: 0 90px;
      }

      .price-section {
        margin: 0;
        padding: 40px 90px;
      }
    }

    @media only screen and (min-width: 1024px) {
      .presentation-section, .fixed-problems-section, .what-can-do-section, .reasons-section,
      .video-section, .how-to-add-section, .feedback-section, .price-section, .faq-section {
        margin: 0 181px;

        h2 {
          margin-bottom: 30px;
          align-self: center;
          text-align: center;
        }
      }

      .blue-title {
        font-size: 2.5rem;
      }

      .presentation-section {
        h1 {
          font-size: 2.8rem;
        }

        h2 {
          font-size: 2.4rem;
          width: 600px;
        }

        p {
          width: 750px;
        }
      }

      .fixed-problems-section, .what-can-do-section, .reasons-section {
        align-items: center;
      }

      .fixed-problems-section, .what-can-do-section {
        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 170px;
          li {
            width: 290px;
          }
        }
      }

      .reasons-section, .how-to-add-section {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .feedback-section {
        display: flex;
        flex-direction: column;
        align-items: center;

        ul {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 170px;

          li {
            width: 280px;
          }

          p, li:first-child p {
            margin-top: 0;
          }
        }
      }

      .price-section {
        ul {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 80px;
        }
      }

      .faq-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        ul {
          li {
            max-width: 550px;
          }
        }
      }
    }
  }
</style>