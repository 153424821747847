<template>
  <div class="hide">
    <transition name="fade">
      <div
        class="backdrop z-9 wh100"
        v-show="isCallWaiterModalVisible"
        @click="toggleCallWaiterModal"
      ></div>
    </transition>
    <transition name="slide">
      <div class="container z-99 flex columns" v-show="isCallWaiterModalVisible">
        <div class="order-container">
          <h2>{{ callWaiterModalText.callWaiter }}</h2>
          <button
            @click="sendNotification"
            class="call-waiter-button flex flex-between align-center"
          >
            <h3>{{ callWaiterModalText.orderButtonText }}</h3>
            <img src="../assets/order.png" alt="order" />
          </button>
        </div>
        <div>
          <h2>{{ callWaiterModalText.askTheBill }}</h2>
          <div class="flex columns flex-between">
            <button
              @click="sendNotification"
              class="call-waiter-button flex flex-between align-center"
            >
              <h3>{{ callWaiterModalText.cashPayment }}</h3>
              <img src="../assets/pay-cash.png" alt="cash payment" />
            </button>
            <button
              @click="sendNotification"
              class="call-waiter-button flex flex-between align-center"
            >
              <h3>{{ callWaiterModalText.cardPayment }}</h3>
              <img src="../assets/pay-card.png" alt="card payment" />
            </button>
          </div>
        </div>

        <div class="flex-center modal-footer">
          <img
            @click="toggleCallWaiterModal"
            class="close-button"
            src="../assets/close-button.png"
            alt=""
          />
        </div>
      </div>
    </transition>

    <ModalButton
      :isModalVisible="isCallWaiterModalButtonVisible"
      class="pulse-button-component"
      @showModal="toggleCallWaiterModal"
    >
      <img class="bell-button-img" src="../assets/bell.png" alt="call waiter" />
    </ModalButton>
  </div>
</template>

<script setup>
  import ModalButton from '@/components/ModalButton.vue'
  import { useStore } from 'vuex'
  import { computed } from 'vue'

  const { state, commit, dispatch, getters } = useStore()
  const isCallWaiterModalVisible = computed(() => state.modals.isCallWaiterModalVisible)
  const isCallWaiterModalButtonVisible = computed(
    () => state.modals.isCallWaiterModalButtonVisible
  )
  const callWaiterModalText = computed(() => getters.callWaiterModalText)

  function toggleCallWaiterModal() {
    commit('TOGGLE_CALL_WAITER_MODAL')
  }

  function sendNotification() {
    commit('SHOW_CALL_WAITER_MODAL', false)
    console.log('send notification')
  }
</script>

<style lang="scss" scoped>
  .pulse-button-component {
    bottom: 130px;
    right: 8px;
  }

  .backdrop {
    position: fixed;
    background-color: rgba(0, 0, 0);
    top: 0;
    left: 0;
    animation: fade 1s ease-in-out forwards;
  }
  .container {
    width: 70%;
    height: calc(100% - 107px);
    background-color: rgb(240, 237, 237);
    top: 53px;
    left: 0;
    padding-left: 18px;
    border-left: 1px solid black;
    position: fixed;
    transform: translateX(0);
    border-bottom-right-radius: 4px;
    overscroll-behavior: contain;
    overflow: auto;

    .order-container {
      margin-top: 20px;
    }

    .call-waiter-button {
      width: 210px;
      height: 70px;
      padding: 16px;
      margin-bottom: 25px;
      background-color: #128b35;
      color: white;
      border-radius: 8px;
      float: left;
      border-style: none;
      -webkit-tap-highlight-color: transparent;

      img {
        height: 32px;
      }
    }

    .close-button {
      margin-left: auto;
      margin-right: 25px;
      margin-bottom: 30px;
      height: 20px;
      width: 20px;
    }
    .modal-footer {
      bottom: 0;
      margin-top: auto;
    }
  }

  .bell-button-img {
    height: 40px;
  }
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s ease-in-out;
  }
  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(-100%);
  }

  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.4;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.1;
    }
  }

  .fade-enter-from {
    animation: fade 0.5s ease-in forwards;
  }
  .fade-leave-to {
    animation: fade-out 0.4s ease-out forwards;
  }
</style>
